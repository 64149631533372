

.bg-white {
    background-color: #fff !important;
}

.small-spinner-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 40px;
}

.small-spinner-wrapper svg {
    font-family: "Russo One", sans-serif;
    width: 100%;
    height: 100%;
}
.small-spinner-wrapper svg text {
    animation: stroke 1.5s infinite alternate;
    stroke-width: 2;
    stroke: #0E0F16;
    font-size: 20px;
}

.small-spinner-wrapper svg text tspan.blue-spinner1 {
    animation: strokeBlue1 1.5s infinite alternate;
    stroke-width: 2;
    stroke: #1493C4;
    font-size: 20px;
}

.small-spinner-wrapper svg text tspan.blue-spinner2 {
    animation: strokeBlue2 1.5s infinite alternate;
    stroke-width: 2;
    stroke: #1493C4;
    font-size: 20px;
}

@keyframes stroke {
    0% {
      fill: rgba(71, 121, 149, 0);
      stroke: rgba(14, 15, 22, 1);
      stroke-dashoffset: 25%;
      stroke-dasharray: 0 50%;
      stroke-width: 1;
    }
    70% {
      fill: rgba(71, 121, 149, 0);
      stroke: rgba(14, 15, 22, 1);
    }
    80% {
      fill: rgba(71, 121, 149, 0);
      stroke: rgba(14, 15, 22, 1);
      stroke-width: 1.5;
    }
    100% {
      fill: rgba(14, 15, 22, 1);
      stroke: rgba(71, 121, 149, 0);
      stroke-dashoffset: -25%;
      stroke-dasharray: 50% 0;
      stroke-width: 0;
    }
}

@keyframes strokeBlue1 {
    0% {
      fill: rgba(71, 121, 149, 0);
      stroke: rgba(36, 117, 134, 1);
      stroke-dashoffset: 25%;
      stroke-dasharray: 0 50%;
      stroke-width: 1;
    }
    70% {
      fill: rgba(71, 121, 149, 0);
      stroke: rgba(36, 117, 134, 1);
    }
    80% {
      fill: rgba(71, 121, 149, 0);
      stroke: rgba(36, 117, 134, 1);
      stroke-width: 1.5;
    }
    100% {
      fill: rgba(36, 117, 134, 1);
      stroke: rgba(71, 121, 149, 0);
      stroke-dashoffset: -25%;
      stroke-dasharray: 50% 0;
      stroke-width: 0;
    }
}

@keyframes strokeBlue2 {
        0% {
          fill: rgba(71, 121, 149, 0);
          stroke: rgba(20, 136, 181, 1);
          stroke-dashoffset: 25%;
          stroke-dasharray: 0 50%;
          stroke-width: 1;
        }
        70% {
          fill: rgba(71, 121, 149, 0);
          stroke: rgba(20, 136, 181, 1);
        }
        80% {
          fill: rgba(71, 121, 149, 0);
          stroke: rgba(20, 136, 181, 1);
          stroke-width: 1.5;
        }
        100% {
          fill: rgba(20, 136, 181, 1);
          stroke: rgba(71, 121, 149, 0);
          stroke-dashoffset: -25%;
          stroke-dasharray: 50% 0;
          stroke-width: 0;
        }
}

.min-w-75 {
    min-width: 250px !important;
}

.accordion-button .accordion-button-custom {
    border: none;
    font-weight: bold;
    font-size: 20px;
    color : #1493C4;
}

.accordion-button:not(.collapsed) .accordion-button-custom {
  color : #fff;
}

.accordion-button:not(.collapsed) .accordion-button-custom .icon-color{
  color : #fff;
}

.accordion-button .accordion-button-custom .icon-color{
  color : #1493C4;
}

.accordion .accordion-custom {
  --bs-accordion-active-color: #f8f5ff;
  --bs-accordion-active-bg: #1493C4;
  border: 1px solid #1493C4;
  border-radius: 0.5rem;
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%2328B2C7%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23ffffff%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
}


.was-validated .form-control:valid, .form-control.is-valid{
  border-color: #28a745 ;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 8 8%27%3e%3cpath fill=%27%2328a745%27 d=%27M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z%27/%3e%3c/svg%3e");
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.25rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
    --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 8 8%27%3e%3cpath fill=%27%2328a745%27 d=%27M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z%27/%3e%3c/svg%3e");
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #28a745;
}

.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: var#28a745;
  box-shadow: 0 0 0 0.25rem rgba(40, 167, 69, 0.25);
}

.form-check-input:checked {
  background-color: #1493C4;
  border-color: #1493C4;
}

.avatar {
  width: 40px;
  height: 40px;
  background-color: transparent;
}

.w-300px {
  width: 300px;
}

.max-w-580px {
  max-width: 580px;
}

.bg-login {
  background-image: url("../../public/media/login/login.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.form-check-input:not(:checked)#custom-switch {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23ffffff%27/%3e%3c/svg%3e");
  background-color: #ff0000; /* Por ejemplo, un fondo rojo */
  border-color: #ff0000; /* Puedes cambiar esto según tus preferencias */
}

.custom-close-btn {
  width: 22px;
  height: 0px;
  background-size: auto;
}

.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: #1493C4;
  border-color: var(--bs-pagination-active-border-color);
}


.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: #1493C4;
  border-color: var(--bs-pagination-active-border-color);
}

.page-link:hover {
  z-index: 2;
  color: #1493C4 !important;
  background-color: var(--bs-pagination-hover-bg);
  border: 1px solid #1493C4 !important;
}

.fixed-div {
  position: fixed;
  z-index: 99;
  width: -webkit-fill-available;
  top: 0;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
}

.max-270px {
  max-width: 270px;
  max-height: 160px;
}

.max-200px {
  max-width: 200px;
  max-height: 160px;
}

.mb-check {
  margin-bottom: 5.5px;
}

.nav-item-custom {
  @extend .nav-item;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  margin: 0.5rem;
}

.nav-item-custom .nav-link-custom:not(.tabActiveCustom) {
  @extend .nav-link;
  color: #1493C4;
  background-color: #fff;
  border: none;
  font-weight: bold;
  border-radius: 0.625rem;
  padding: 0,75rem;
}

.nav-item-custom .nav-link-custom:not(.tabActiveCustom):hover {
  color: #1f2128;
  background-color: #e7eef8;
  border-color: #e7eef8;
}

.nav-item-custom .nav-link-custom {
  @extend .nav-link;
  color: #fff;
  background-color: #1493C4;
  border: none;
  font-weight: bold;
  border-radius: 0.625rem;
  padding: 0,75rem;
}

.nav-item-custom .nav-link-custom:focus {
  color: #fff;
  background-color: #1493C4;
}

.nav-item-custom .nav-link-custom:hover {
  color: #fff;
  background-color: #1493C4;
}

.svg-icon.svg-icon-custom svg {
  height: 1.5rem !important;
  width: 1.55rem !important;
}

$wizard-button-size: 2rem;

.wizard {
	padding-top: $spacer;
}

.wizard-progress {
	margin: ($wizard-button-size * 0.5) ($wizard-button-size * 0.5)
		(($wizard-button-size * 0.5) + $spacer);

	> .progress {
		height: 3px;
	}

	.wizard-progress-btn {
		width: $wizard-button-size;
		height: $wizard-button-size;
	}
}

.bnt-wizard {
  background-color: #fff;
  border-color:#1493C4;
  color: #1493C4;
  font-weight: bold;
  border-radius: 0.625rem;
  padding: 0,75rem;
}

.max-50px {
  max-width: 50px;
  max-height: 50px;
}

@media (max-width: 539px) {
  .justify-page-title {
    justify-content: center !important; /* !important para asegurar que esta regla tenga prioridad */
  }
}

@media (min-width: 540px) {
  .justify-page-title {
    justify-content: space-between !important; /* !important para asegurar que esta regla tenga prioridad */
  }
}

@media (min-width: 600px) {
  .rdrDefinedRangesWrapper {
    display: block !important;
  }
}

@media (max-width: 599px) {
  .rdrDefinedRangesWrapper {
    display: none !important;
  }
}

.btn-close-light {
  --bs-btn-close-color: #ffffff;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23f6f6f6%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e");
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: none;
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 0.75rem;
  height: 0.75rem;
  padding: 0.25em 0.25em;
  color: #ffffff;
  background: transparent var(--bs-btn-close-bg) center / 1.5rem auto no-repeat;
  border: 0;
  border-radius: 0.475rem;
  opacity: var(--bs-btn-close-opacity);
}


.app-search {
  padding: calc(32px * 0.5) 0;
}

.app-search .form-control {
  border: none;
  height: 38px;
  padding-left: 40px;
  padding-right: 20px;
  background-color: $input-solid-bg;
  border-color: $input-solid-bg;
  color: $input-solid-color;
  @include placeholder($input-solid-placeholder-color);
  transition: $transition-input;
  -webkit-box-shadow: none;
          box-shadow: none;
  border-radius: 5px;
}
.app-search span {
  position: absolute;
  z-index: 10;
  font-size: 16px;
  line-height: 38px;
  left: 5px;
  top: 0;
  color: var(--bs-secondary-color);
}

.badge-valid {
  background-color: #009252 !important;
  color: #fff !important;
}

.badge-invalid {
  background-color: #F0416C  !important;
  color: #fff !important;
}

.badge-pending {
  background-color: #FFC210 !important;
  color: #fff !important;
}


// Desktop mode
@include media-breakpoint-up(lg) {
  
  .aside-user {
    transition: all 0.3s ease-in-out !important;
  }

	[data-kt-app-sidebar-minimize=on] .aside-user {
    border-top: 1px dashed #1f212a;
    padding: 0 10px;
  }

  [data-kt-app-sidebar-minimize=off] .aside-user {
    padding: 0 25px;
  }
}







//
// Toolbar
//

// General
.toolbar {
  display: flex;
  align-items: center;
  width: 100%;
  .container-xxl {
    max-width: none;
    padding: 0 15px;
  }
  
}

.toolbar-buttons {
  justify-content: center;
  align-items: center !important;
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
.toolbar {
      flex-direction: column;
      background: var(--bs-app-header-base-bg-color);
  }
}


// Desktop mode
@include media-breakpoint-up(lg) {
	.header {
    display: none;
		align-items: center;
		position: fixed;
		height: 74px;
		transition: left .3s ease;
		z-index: 100;
		box-shadow: var(--bs-app-header-base-box-shadow);
		background-color: var(--bs-app-header-base-bg-color);
		border-bottom: var(--bs-app-header-base-border-bottom);
		top: 0;
		right: 0;
		left: 0;
	}
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
	.header {
    display: flex;
		flex-direction: column;

  }
}